.chat-settings-section{
  background: #FBFBFD;
  border-radius: 5px 5px 0px 0px;
  width: 970px;
  margin-bottom: 40px;
  &-body{
    background: #FFFFFF;
    padding: 30px 50px 30px 58px;
  }
  &-header{
    background: #fbfbfd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px 5px 0px 0px;
    padding-left: 20px;
    padding-right: 18px;
    height: 47px;
    &-title{
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #9296AC;
    }
    &-description{
      font-family: National;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 125.56%;
      text-align: right;
      color: #9296AC;
    }
  }
}
