.select-bot-dropdown{
  min-width: 400px !important;
  .search-container{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    .ant-input-affix-wrapper-focused{
      box-shadow: none!important;
      border: none;
    }
    .ant-input-affix-wrapper{
      background: #F5F5F5;
      border-radius: 4px;
      width: 90%;
      border: none;
      input{
        background: #F5F5F5;
      }
      &:hover {
        border: none;
      }
      &:active {
        border: none;
      }
      &:focus {
        border: none;
        box-shadow: none!important;
      }
    }
  }
  .ant-select-item-option-selected{
    background: #FFFFFF;
    .select-bot-header-bot-select-item{
      .bot-select-item-name{
        color: #3658E2;
      }
    }
  }
  .select-bot-header-bot-select-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bot-select-item-name{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      display: flex;
      align-items: center;
      color: #000000
    }
    .bot-select-item-deploy{
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 125.56%;
      text-align: right;
      color: #9296AC;
    }
  }
}
.select-bot-header{
  height: 37px;
  background: #190DA8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 17px;
  padding-left: 23px;
  .bot-select{
    display: flex;
    align-items: center;
    .organization-name{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #AEABFF;
    }
    .all-bots{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      margin-left: 7px;
    }
    .ant-select{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      .ant-select-arrow{
        color: #FFFFFF;
      }
    }
  }
  .bot-settings{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125.56%;
    text-align: right;
    color: #9E9CFC;
    span{
      margin-right: 10px;
    }
  }
  .bot-last-deploy{
    font-family: National;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125.56%;
    text-align: right;
    color: #9E9CFC;
  }
}
