@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 100;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-Thin-BETA.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-Thin-BETA.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 100;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-ThinItalic-BETA.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-ThinItalic-BETA.woff?v=3.11") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 200;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-ExtraLight-BETA.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-ExtraLight-BETA.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 200;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-ExtraLightItalic-BETA.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-ExtraLightItalic-BETA.woff?v=3.11") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-Light-BETA.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-Light-BETA.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 300;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-LightItalic-BETA.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-LightItalic-BETA.woff?v=3.11") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-Regular.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-Regular.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-Italic.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-Italic.woff?v=3.11") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-Medium.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-Medium.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-MediumItalic.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-MediumItalic.woff?v=3.11") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-SemiBold.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-SemiBold.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 600;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-SemiBoldItalic.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-SemiBoldItalic.woff?v=3.11") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-Bold.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-Bold.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-BoldItalic.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-BoldItalic.woff?v=3.11") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 800;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-ExtraBold.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-ExtraBold.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 800;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-ExtraBoldItalic.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-ExtraBoldItalic.woff?v=3.11") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-Black.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-Black.woff?v=3.11") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/webfonts/Inter/Inter-BlackItalic.woff2?v=3.11") format("woff2"),
         url("../assets/webfonts/Inter/Inter-BlackItalic.woff?v=3.11") format("woff");
  }

  /* -------------------------------------------------------
  Variable font.
  Usage:

    html { font-family: 'Inter', sans-serif; }
    @supports (font-variation-settings: normal) {
      html { font-family: 'Inter var', sans-serif; }
    }
  */
  @font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url("../assets/webfonts/Inter/Inter-roman.var.woff2?v=3.11") format("woff2");
  }
  @font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url("../assets/webfonts/Inter/Inter-italic.var.woff2?v=3.11") format("woff2");
  }


  /* --------------------------------------------------------------------------
  [EXPERIMENTAL] Multi-axis, single variable font.

  Slant axis is not yet widely supported (as of February 2019) and thus this
  multi-axis single variable font is opt-in rather than the default.

  When using this, you will probably need to set font-variation-settings
  explicitly, e.g.

    * { font-variation-settings: "slnt" 0deg }
    .italic { font-variation-settings: "slnt" 10deg }

  */
  @font-face {
    font-family: 'Inter var experimental';
    font-weight: 100 900;
    font-display: swap;
    font-style: oblique 0deg 10deg;
    src: url("../assets/webfonts/Inter/Inter.var.woff2?v=3.11") format("woff2");
  }
