.chat-settings-item-header{
  &-title{
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    color: #201F1D;
    margin-bottom: 6px;
  }
  &-description{
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    color: #9296AC;
  }
}
