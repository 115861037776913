.idea-graph-node-path {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 45px;
  padding-left: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  &-item {
    display: flex;
    align-items: baseline;
    line-height: 0px;
    cursor: pointer;
    &:last-child {
      .divider {
        opacity: 0;
      }
    }
  }
  .ant-tag {
    font-size: 14px;
    border: 1px solid #3658e2;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: inline-block;
  }
  .divider {
    font-size: 18px;
    color: #d1d1d9;
    margin: 0px 10px 0px 4px;
    user-select: none;
  }
}
