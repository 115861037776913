.graph-spaces {
  height: 100%;
  background-color: #f6f5f9;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;
    height: calc(100vh - 65px);
    overflow-y: auto;
  }
}

.graph-spaces-section {
  width: 90%;
  padding-bottom: 40px;
}

.graph-space-text {
  font-size: 17px;
  line-height: 26px;
  color: #000000;
  padding: 0px 2px !important;
  background: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
  &.__lg {
    font-weight: 500;
    font-size: 19px;
    line-height: 20px;
  }
  &.__sm {
    font-size: 13px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #201f1d;
  }
  &.__click {
    cursor: pointer;
  }
  &.__info {
    font-size: 13px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #94949b;
    white-space: nowrap;
    text-align: right;
  }
  &.__flex {
    display: flex;
    align-items: center;
  }
  &.__crop {
    max-width: 270px;
    display: inline-block;
    white-space: nowrap;
  }
  &.__link {
    font-size: 13px;
    line-height: 20px;
    text-decoration-line: underline;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #3658E2;
  }
}

.graph-space-table {
  width: 100%;
  .ant-table {
    background: transparent;
  }
  .ant-table-content {
    table {
      background: #e0e0e7;
    }
    .ant-table-thead tr {
      height: 37px;
      background: #f6f5f9;
      .ant-table-cell {
        border-top: 1px solid #e0e0e7;
        border-bottom: 1px solid #e0e0e7;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        color: #a5a5bb;
        background: transparent;
      }
    }
  }
  .graph-space-table-row {
    background: #f6f5f9;
    height: 45px;
    .ant-table-cell {
      background: #f6f5f9 !important;
      border-color: #e0e0e7;
    }
  }
}

.graph-space-module-type {
  background-color: white;
  padding: 0px 10px;
  border-radius: 20px;
  color: #201f1d;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.global {
    background: #f4bc47;
  }
  &.customer-info {
    background: #61c5f1;
    color: white;
  }
  &.catalog {
    background: #025ad1;
    color: white;
  }
  &.prelinked_faqs {
    background: #eb5a64;
    color: white;
  }
  &.complete-language-model {
    background: #f6c8db;
  }
  &.custom-faq {
    background: #f6f5f9;
  }
}

.graph-space-card {
  height: 115px;
  width: 330px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 10px rgba(1, 4, 77, 0.04);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &.__flex {
    height: auto;
  }
  &-content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 18px;
    .anticon-more {
      position: absolute;
      right: 5px;
      top: 10px;
      cursor: pointer;
    }
  }
  &-footer {
    flex-shrink: 0;
    height: 40px;
    background: #f9f9fc;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .graph-space-module-type {
      max-width: 100px;
    }
  }
}

.graph-space-grid {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.graph-space-btn {
  background-color: #f1f1f6;
  border-radius: 3px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  display: flex;
  align-items: center;
  border: none;
  &:hover {
    background-color: #f1f1f6;
  }
  &.__ghost {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    &:hover {
      background-color: transparent;
    }
  }
}

.graph-space-input {
  height: 32px;
  background-color: #f1f1f6;
  border-radius: 3px;
  border: none;
  box-shadow: none;
  outline: none;
}

.graph-space-form {
  margin: 0;
  .ant-form-item{
    margin: 0;
  }
}

.graph-space-menu {
  padding: 15px 10px;
  border-radius: 3px;
  .ant-dropdown-menu-item {
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-disabled {
      .graph-space-key {
        color: inherit !important;
      }
    }
    .graph-space-key {
      font-size: 14px;
      line-height: 26px;
      color: #3658e2;
      letter-spacing: 1.5px;
      &::first-letter {
        font-weight: 600;
      }
    }
  }
}

.graph-space-card-collapse {
  .ant-collapse-header {
    padding: 0 !important;
  }
  .ant-collapse-content-box {
    margin-top: 16px;
    padding: 16px 0px !important;
    border-top: 1px solid #E8E8E8;
  }
}

.graph-space-card-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.graph-space-card-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}