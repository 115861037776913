.idea-graph-header {
  height: 65px;
  background: #ffffff;
  box-shadow: -2px 0px 7px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  &-info {
    .idea-graph-version {
      font-family: Fira Code;
      font-weight: 300;
      font-size: 11px;
      padding-top: 5px;
      line-height: 10px;
      color: #94949b;
      user-select: none;
    }
  }

  &-search {
    .ant-select {
      width: 400px !important;
      background: #F5F5F5;
      border-radius: 4px;
      .ant-input-affix-wrapper {
        background:#F5F5F5;
        border: none !important;
        outline: none;
        box-shadow: none;
        input {
          background-color: #F5F5F5;
        }
      }
      .ant-input-group-addon {
        .ant-btn {
          background-color: #F5F5F5;
          border: none;
        }
      }
    }
  }

  &-options {
    padding: 0px 20px;
    white-space: nowrap;
  }

  &-btn {
    background-color: #f1f1f6;
    border-radius: 3px;
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    &[disabled] {
      opacity: 0.5;
    }
    &:hover {
      background-color: #f1f1f6;
    }
    &.__static {
      background-color: #f1f1f6 !important;
      color: #000000 !important;
    }
    &.__ghost {
      background-color: transparent;
      box-shadow: none;
      outline: none;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &-group {
    &.__flex {
      display: flex;
      align-items: center;
    }
  }

  .idea-graph-status {
    background: #72f2d0;
    border-radius: 2px;
    padding: 0px 10px;
    white-space: nowrap;
    margin-right: 15px;
    cursor: default;
    user-select: none;
  }
}

.graph-space-popover {
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}