
@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-Light.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-Light.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-Black.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-Black.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-BoldItalic.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-BoldItalic.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-MediumItalic.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-MediumItalic.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-LightItalic.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-LightItalic.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-BlackItalic.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-BlackItalic.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-Medium.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-Medium.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-Bold.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-Bold.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-Regular.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-Regular.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-Semibold.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-Semibold.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('../assets/webfonts/TiemposHeadlineWeb-SemiboldItalic.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-SemiboldItalic.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Tiempos Headline Regular';
    src: url('../assets/webfonts/TiemposHeadlineWeb-RegularItalic.eot');
    src: url('../assets/webfonts/TiemposHeadlineWeb-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/webfonts/TiemposHeadlineWeb-RegularItalic.woff2') format('woff2'),
        url('../assets/webfonts/TiemposHeadlineWeb-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
