.graph-space-header {
  height: 65px;
  background: #ffffff;
  box-shadow: -2px 0px 7px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  &-nav {
    display: flex;
    align-items: center;
    &-item {
      font-size: 18px;
      line-height: 16px;
      color: #201f1d;
      margin-left: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:first-child {
        margin-left: 0px;
      }
      .nav-item-prefix {
        .anticon {
          margin-right: 5px;
          font-size: 14px;
        }
      }
      .nav-item-content {
        display: inline-block;
      }
    }
  }

  &-search {
    .ant-select {
      width: 400px !important;
      background: #f5f5f5;
      border-radius: 4px;
      .ant-input-affix-wrapper {
        background: #f5f5f5;
        border: none !important;
        outline: none;
        box-shadow: none;
        input {
          background-color: #f5f5f5;
        }
      }
      .ant-input-group-addon {
        .ant-btn {
          background-color: #f5f5f5;
          border: none;
        }
      }
    }
  }

  &-options {
    padding: 0px 20px;
    white-space: nowrap;
  }

  &-btn {
    background-color: #f1f1f6;
    border-radius: 3px;
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    &:hover {
      background-color: #f1f1f6;
    }
    &.__ghost {
      background-color: transparent;
      box-shadow: none;
      outline: none;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &-group {
    &.__flex {
      display: flex;
      align-items: center;
    }
  }

  .graph-space-version {
    font-family: Fira Code;
    font-weight: 300;
    font-size: 11px;
    padding-top: 5px;
    line-height: 10px;
    color: #94949b;
    user-select: none;
    text-transform: uppercase;
  }

  .graph-space-status {
    background: #72f2d0;
    border-radius: 2px;
    padding: 0px 10px;
    white-space: nowrap;
    margin: 0px 20px;
    cursor: default;
    user-select: none;
  }
}
