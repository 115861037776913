.app-progress {
  width: 450px;
  display: inline-flex;
  align-items: center;

  &-text {
    white-space: nowrap;
    margin-right: 10px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
  }
  &-btn {
    margin-left: 10px;
  }
}
