.graph-space-widget {
  width: 400px;
  background: #ffffff;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &-header {
    flex-shrink: 0;
    padding: 24px;
    .ant-input-search {
      background: #F5F5F5;
      border-radius: 4px;
      overflow: hidden;
      .ant-input {
        border-radius: 4px;
        font-size: 15px;
      }
      .ant-input-group-addon {
        .ant-btn {
          background-color: #F5F5F5;
          border: none;
        }
      }
    }
  }
  &-body {
    .graph-space-widget-list {
      height: 300px;
      overflow-y: auto;
      .graph-space-widget-entry {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0px 24px;
        &:hover {
          background-color: #F5F5F5;
        }
        .widget-entry-text {
          white-space: nowrap;
          display: flex;
          align-items: baseline;
          span {
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            display: inline-block;
          }
          &[data-selected='true'] {
            span {
              color: #025ad1;
            }
          }
        }
      }
    }
  }
  &-footer {
    flex-shrink: 0;
    .graph-space-menu {
      padding: 0px;
      .ant-menu-item {
        font-size: 16px;
        line-height: 26px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #E4E4EA;
        margin: 0px !important;
        padding: 0px 24px;
        #module-delete {
          display: flex;
        }
        #module-name {
          max-width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          padding-right: 5px;
        }
        .graph-space-key {
          font-size: 14px;
          line-height: 26px;
          color: #3658e2;
          letter-spacing: 1.5px;
          &::first-letter {
            font-weight: 600;
          }
        }
      }
    }
  }
}
