@import './css/TiemposFont.scss';
@import './css/NationalFont.scss';
@import './css/InterFont.scss';

:root {
  --app-banner-height: 0px;
  --app-header-height: 62px;
  --details-sidebar-width: 37rem;
}

html, body, #root, .App, .mainContent {
  height: 100%;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  margin: 0 auto;
  width: 100%;
}

.mainContent {
  background-color: #fff;
}
