#g-globalnav {
  --nav-background: #FAFAFD;
  background-color: var(--nav-background);
  border-right: 0.5px solid #d1d1df;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    .g-globalnav-menu {
      padding-top: 30px;
    }
  }
  .ant-layout-sider-trigger {
    display: flex;
    height: 70px;
    background: white;
    background-color: var(--nav-background);
    border-right: 0.5px solid #d1d1df;
  }
}

.g-globalnav-menu-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  .star-upgrade-button{
    margin-bottom: 4px;
    transform: translateX(1px);
    &:hover{
      cursor: pointer;
    }
  }
}

.g-globalnav-sider-header {
  height: 63px;
  overflow: hidden;
  .sider-header-logo {
    padding: 18px 20px;
  }
}

.g-globalnav-sider-trigger {
  display: flex;
  flex-grow: 1;
  &.collapsed {
    .sider-trigger-info {
      border-right: none;
    }
  }
  .anticon-double-left,
  .anticon-double-right {
    color: #9ea1b6;
    padding: 5px;
  }
  .sider-trigger-info {
    width: 65px;
    position: relative;
    flex-shrink: 0;
    border-right: 0.5px solid #d1d1df;
    .anticon-double-right {
      position: absolute;
      top: -5px;
      left: 20px;
    }
    .app-menu-user {
      margin: 20px 0px 0px 18px;
      .app-menu-info {
        position: relative;
        left: 35px;
        .menu-info-name-text {
          max-width: 120px;
        }
      }
    }
  }
  .sider-trigger-info-expanded {
    flex-grow: 1;
    position: relative;
    .anticon {
      position: absolute;
      right: 16px;
      top: 22.5px;
    }
  }
}

.g-globalnav-menu-sider {
  display: flex;
  flex-grow: 1;
}

.g-globalnav-menu-wrapper {
  &.menu-wrapper-sections {
    width: 65px;
    flex-shrink: 0;
    border-right: 0.5px solid #d1d1df;
  }
  &.menu-wrapper-routes {
    flex-grow: 1;
    .side-menu-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #9ea1b6;
      margin: 0px 16px 8px 24px;
      padding: 25px 0px 2px 0px;
      border-bottom: 0.5px solid #d1d1df;
      user-select: none;
    }
  }
}
.ant-menu-inline-collapsed-tooltip{
  .ant-tooltip-inner{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F6F6FA;
    background-color: #38384B;
  }
}

.menu-header{
  margin-left: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #B8BAC9;
}

.g-globalnav-menu {
  margin: 0;
  width: 64px;
  border: none;
  background-color: var(--nav-background);
  .menu-header{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #B8BAC9;
  }
  .ant-menu-submenu {
    &[data-selected="true"] {
      .anticon {
        svg {
          fill: none;
          path {
            stroke: #1d39c4 !important;
          }
        }
      }
    }
    .ant-menu-submenu-title {
      padding-left: 22px !important;
      .anticon {
        font-size: 22px;
      }
    }
  }
  .ant-menu-item {
    &[data-selected="true"] {
      .ant-menu-item-icon {
        fill: none;
        path {
          stroke: #1d39c4 !important;
        }
      }

    }
    padding-left: 22px !important;
    text-overflow: clip;
    &-selected {
      background-color: initial !important;
      &::after {
        transition: none;
        border-color: #1d39c4;
      }
      .anticon {
        svg {
          path {
            fill: #1d39c4 !important;
          }
        }
      }
    }
    .anticon {
      font-size: 22px;
    }
  }
}

.g-globalnav-side-menu {
  border: none;
  background-color: var(--nav-background);
  .ant-menu-item {
    height: 23px;
    line-height: 20px;
    margin-bottom: 4px !important;
    a {
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      &.active {
        color: #1d39c4;
      }
    }
  }
}

.star-upgrade-tooltip{
  .ant-tooltip-inner{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #F6F6FA;
    background-color: #38384B;
  }
}

.g-globalnav-menu-popup {
  width: 205px;
  box-shadow: 7px 0px 21px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  .ant-menu {
    padding: 15px 8px;
    &-item {
      font-size: 17px;
      line-height: 20px;
      color: #000000;
      height: 23px;
      margin-bottom: 4px !important;
    }
  }
}
