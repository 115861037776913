.auth-select-provider{
  display: flex;
  flex-direction: column;

  .button-wrapper-google{
    width: 190px;
    margin-bottom: 12px;
    span{
      font-size: 17px;
      font-weight: 500;
      line-height: 22px;
    }
    img{
      height: 20px;
      width: 20px;
      margin-top: -5px;
    }
  }
  .button-wrapper-email{
    width: 190px;
    margin-bottom: 12px;
    span{
      font-size: 17px;
      font-weight: 500;
      line-height: 22px;
    }
    i{
      background-color: #ff4474;
      padding: 3px 2px 2px;
      border-radius: 4px;
    }
  }
}

.terms-section{
  width: 320px;
  margin-top: 69px;
  span{
    color:#1B71FF;
    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }
  opacity: 0.66;
  color: rgba(0,0,0,0.9);
  font-size: 13px;
  line-height: 18px;
}
