.select-email{
  display: flex;
  flex-direction: column;

  &.mobile{
    width: 100%;
  }

  .add-account {
    height: 17px;
    color: #FF444E;
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
    &:hover{
      cursor: pointer;
    }
  }

  .button-wrapper{
    width: 250px;
    margin-bottom: 12px;
    .ant-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      padding-left: 15px;
      padding-right: 7px;
    }
    .left-side{
      display: flex;
      align-items: center;

      .email-span{
        width: 220px;
        margin-left: 10px;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        margin-top: -2px;
        text-align: left;
      }
    }

    .anticon{
      background-color: rgba(43,45,66,0.07);
      padding: 3px 2px 2px;
      border-radius: 4px;
    }
  }
}
