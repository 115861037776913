@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.app-header {
  height: var(--app-header-height);
  flex-shrink: 0;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.07);
  z-index: 100;

  &-group {
    display: flex;
    align-items: center;
    position: relative;
  }
  &-btn {
    background-color: #f1f1f6 !important;
    border-radius: 3px;
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    span {
      color: #000000;
    }
    .anticon {
      font-size: 14px;
    }
    &:hover {
      background-color: #e7e7e7 !important;
    }
    &.__prim {
      background-color: #3658e2 !important;
      span {
        color: white !important;
      }
      &:hover {
        background-color: #1f3fc2 !important;
      }
    }
    &.__ghost {
      background-color: transparent;
      box-shadow: none;
      outline: none;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.header-prim-btn {
  color: #ffffff;
  font-size: 15px;
  margin-right: 36px;
  background-color: #db295e;
  border: none;
  display: flex;
  align-items: center;

  & > .anticon {
    margin-top: 4px;
  }
}

.header-sec-btn {
  color: rgba(0, 0, 0, 0.9);
  font-family: National;
  font-size: 15px;
  margin-right: 12px;
}

.exp-is-cloning {
  position: fixed;
  background: white;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  text-decoration: blink;
  animation: blinker 0.8s ease-in-out alternate infinite;
  z-index: 50;
}

.header-status {
  margin-left: 15px;
  color: #2b2d42;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-transform: capitalize;
  box-sizing: border-box;
  padding: 2px 5px;
  user-select: none;
  cursor: default;

  &.draft {
    border-radius: 2px;
    background-color: #faf6d9;
  }

  &.active {
    border-radius: 2px;
    background-color: #e8f1ff;
  }

  &.completed {
    border-radius: 2px;
    background-color: #d5ebc7;
  }

  &.archived {
    border: 1px solid rgba(43, 45, 66, 0.3);
    border-radius: 2px;
  }
}

.breadcrumb-container {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  .ant-breadcrumb-separator {
    margin: 0;
    color: #8c8c8c;
    padding: 0 5px;
  }
  .ant-breadcrumb {
    font-size: 16px;
    line-height: 20px;
    color: #201F1D;
  }
}

.app-header-group {
  &.__flex-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
