.verify-email{
  display: flex;
  flex-direction: column;

  .button-wrapper{
    width: 190px;
    margin-bottom: 12px;
    span{
      font-size: 17px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}