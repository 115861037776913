.graph-space {
  height: 100%;
  background-color: #f6f5f9;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px 0px;
    height: calc(100vh - 65px);
    overflow-y: auto;
  }
  &-section-flex {
    display: flex;
    justify-content: space-between;
  }
  &-section {
    width: 90%;
  }
  .ant-input-affix-wrapper {
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    .anticon {
      color: #94949b;
    }
    .ant-input {
      font-size: 16px;
      line-height: 20px;
      font-feature-settings: "tnum" on, "lnum" on;
      background: transparent;
    }
  }
}
