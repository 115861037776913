@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-Light.eot');
	src:
	url('../assets/webfonts/NationalWeb-Light.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-Light.woff2') format('woff2');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-LightItalic.eot');
	src:
	url('../assets/webfonts/NationalWeb-LightItalic.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-LightItalic.woff2') format('woff2');
	font-weight: 200;
    font-style: italic;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-Book.eot');
	src:
	url('../assets/webfonts/NationalWeb-Book.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-Book.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-BookItalic.eot');
	src:
	url('../assets/webfonts/NationalWeb-BookItalic.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-BookItalic.woff2') format('woff2');
	font-weight: 300;
    font-style: italic;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-Regular.eot');
	src:
	url('../assets/webfonts/NationalWeb-Regular.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-RegularItalic.eot');
	src:
	url('../assets/webfonts/NationalWeb-RegularItalic.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-RegularItalic.woff2') format('woff2');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-Medium.eot');
	src:
	url('../assets/webfonts/NationalWeb-Medium.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-MediumItalic.eot');
	src:
	url('../assets/webfonts/NationalWeb-MediumItalic.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-MediumItalic.woff2') format('woff2');
	font-weight: 500;
	font-style: italic;
}



@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-Semibold.eot');
	src:
	url('../assets/webfonts/NationalWeb-Semibold.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-Semibold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-SemiboldItalic.eot');
	src:
	url('../assets/webfonts/NationalWeb-SemiboldItalic.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-SemiboldItalic.woff2') format('woff2');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-Bold.eot');
	src:
	url('../assets/webfonts/NationalWeb-Bold.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-BoldItalic.eot');
	src:
	url('../assets/webfonts/NationalWeb-BoldItalic.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-BoldItalic.woff2') format('woff2');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-Extrabold.eot');
	src:
	url('../assets/webfonts/NationalWeb-Extrabold.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-Extrabold.woff2') format('woff2');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-ExtraboldItalic.eot');
	src:
	url('../assets/webfonts/NationalWeb-ExtraboldItalic.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-ExtraboldItalic.woff2') format('woff2');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-Black.eot');
	src:
	url('../assets/webfonts/NationalWeb-Black.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-Black.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'National';
	src: url('../assets/webfonts/NationalWeb-BlackItalic.eot');
	src:
	url('../assets/webfonts/NationalWeb-BlackItalic.woff') format('woff'),
	url('../assets/webfonts/NationalWeb-BlackItalic.woff2') format('woff2');
	font-weight: 900;
	font-style: italic;
}
