.app-menu-user {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  z-index: 100;
  height: 40px;

  .app-menu-avatar {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .app-menu-info {
    text-align: left;
    flex-grow: 1;
    flex-shrink: 0;
    .menu-info-name {
      font-size: 17px;
      line-height: 20px;
      color: #000000;
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      &-text {
        display: block;
        white-space: nowrap;
        max-width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .anticon-down {
        color: #000000;
        font-size: 10px;
        position: relative;
        padding-left: 5px;
        top: 1px;
      }
    }
    .menu-info-org {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #BCBCCB;
      text-transform: uppercase;
    }
  }
}

.app-menu {
  padding: 0;
  box-shadow: 7px 0px 21px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  width: 200px;
  padding-bottom: 12px;
  &-header {
    padding: 15px 30px;
    height: 65px;
    margin-bottom: 12px;
    border-bottom: 1px solid #E8E8E8;
    &-name {
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #9598AC;
    }
    &-org {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #BABDCD;
      text-transform: uppercase;
    }
  }
  .ant-dropdown-menu-item {
    padding: 2px 30px;
    .menu-item-text {
      font-size: 17px;
      line-height: 25px;
      color: #000000;
    }
    .menu-item-upgrade{
      display: block;
      transform: translateX(-10px);
      background: #72F2D0;
      border-radius: 40px;
      padding: 4px 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 135.2%;
      text-transform: uppercase;
      color: #000000;
    }
  }
}
