.email-password-auth{
  width: 550px;
  &.mobile{
    width: 100%;
  }
  .input-wrapper{
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 32px;
  }
  &-bottom{
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .login-button{
      span{
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
      }
      i{
        background-color: #ff4474;
        padding: 3px 2px 2px;
        border-radius: 4px;
      }
    }
  }
}
