.idea-graph-editor-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 1380px;

  a {
    color: #000;
  }

  .header-controls {
    margin-right: 20px;
  }

  .below-header {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
}

.idea-graph-meta-editor {
  .idea-graph-meta-col {
    display: flex;
    white-space: nowrap;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    b,
    input {
      margin-left: 6px;
    }
  }
}

.idea-graph-menu {
  padding: 15px 10px;
  border-radius: 3px;
  .ant-dropdown-menu-item {
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-disabled {
      .idea-graph-key {
        color: inherit !important;
      }
    }
    .idea-graph-key {
      font-size: 14px;
      line-height: 26px;
      color: #3658e2;
      letter-spacing: 1.5px;
      &::first-letter {
        font-weight: 600;
      }
    }
  }
}

.idea-graph-options-btn {
  background-color: #f1f1f6;
  border-radius: 3px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  &:hover {
    background-color: #f1f1f6;
  }
  &.__ghost {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    &:hover {
      background-color: transparent;
    }
  }
}

.graph-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.graph-panel {
  flex: 1 1 auto;
  padding: 80px 40px;
  height: max-content;
  min-height: 100%;
  position: relative;
  padding-right: 500px;
}

.idea-node-tooltip {
  min-width: 270px;
  .ant-tooltip-arrow {
    &-content {
      background-color: #ffffff;
    }
  }
  .ant-tooltip-inner {
    background: #ffffff;
    border-radius: 3px;
    color: #201f1d;
    overflow: hidden;
    .node-tooltip-content {
      .tooltip-node-icon {
        position: absolute;
        top: 25px;
        left: 16px;
      }
      .tooltip-node-title {
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #201f1d;
        padding: 10px 0px;
        border-bottom: 1px solid #e4e4ea;
        margin: 0px 20px 0px 40px;
      }
      .tooltip-node-info {
        font-size: 13px;
        line-height: 20px;
        color: #000000;
        padding: 10px 0px;
        border-bottom: 1px solid #e4e4ea;
        margin: 0px 20px 0px 40px;
        span {
          color: #3658e2;
        }
      }
      .tooltip-node-desc {
        font-size: 12px;
        line-height: 16px;
        color: #adadb6;
        padding: 10px 0px;
        margin: 0px 20px 0px 40px;
      }
      .tooltip-node-footer {
        height: 40px;
        background: #f5f5f7;
        margin: 0px -8px -6px -8px;
        border-top: 1px solid #e4e4ea;
      }
    }
  }
}

.idea-graph-name {
  display: flex;
  align-items: center;
  .ant-input,
  .graph-name-text {
    border: none;
    height: 22px;
    padding: 0 !important;
    outline: none;
    box-shadow: none;
    font-family: National;
    color: #201f1d;
    text-overflow: ellipsis;
    transition: none;
    min-width: 80px;
    max-width: 185px;
    background: #f5f5f5;
    font-size: 16px;
    vertical-align: middle;
  }
  .graph-name-text {
    border-top: 1px solid transparent;
    width: auto;
    white-space: nowrap;
    font-size: 18px;
    line-height: 20px;
    background: transparent;
    overflow: hidden;
    min-width: auto;
    &:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
  }
  .anticon-edit {
    margin-left: 10px;
    color: #bfbfc9;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #9a9aa1;
    }
  }
}

.graph-grid {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  &-bk {
    height: 100%;
    width: 100%;
    background: #f7f7f8;
    display: flex;
    overflow: hidden;
  }
  &-col {
    height: 100%;
    width: 250px;
    border-right: 1px solid #e5e5e5;
    text-align: center;
    flex-shrink: 0;
    &:hover {
      .graph-grid-col-toggle {
        display: initial;
      }
    }
    &:first-child {
      width: 40px;
      .graph-grid-col-toggle {
        display: none;
      }
    }
    &-toggle {
      position: relative;
      top: 10px;
      cursor: pointer;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-transform: uppercase;
      color: #a9a9b5;
      display: none;
    }
  }
}

.idea-graph-select {
  width: 100%;
  .ant-select-selection-item {
    .type-icon {
      display: none !important;
    }
  }
  &-drop {
    .ant-select-item {
      &-option-selected {
        font-weight: normal;
      }
      &-option-content {
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        display: flex;
        align-items: center;
        .type-icon {
          margin-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
          width: 20px;
          flex-shrink: 0;
          color: lightgray;
        }
      }
    }
  }
  .ant-select-selector {
    background-color: #f1f1f6 !important;
    border: none !important;
    border-radius: 3px !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.idea-node-title {
  padding: 0px 8px 0px 0px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  &-utt {
    background: #060727;
    box-shadow: 0px 0px 9px rgba(6, 6, 45, 0.08);
    border-radius: 3px;
    font-family: Fira Code;
    font-size: 11px;
    color: white;
    padding: 0px 4px;
    border: 1px solid #d9d9e0;
  }
  &-prefix {
    color: #c7c7cb;
    font-size: 14px;
    min-width: 18px;
    text-align: center;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    svg {
      width: 100%;
    }
  }
  &-label {
    font-size: 16px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #201f1d;
  }
}

.subtree {
  --satisfied: #46c275;
  --unsatisfied: #FF6056;
  --unsatisfied-text: #D84239;
  --initial: #3658E2;
  position: relative;
  white-space: nowrap;
  &.cl-node-line {
    &.satisfied,
    &.unsatisfied,
    &.initial {
      &::before {
        content: "";
        position: absolute;
        top: 18px;
        left: -1.5px;
        height: 100%;
        width: 2px;
        z-index: 2;
      }
    }
    &.satisfied {
      &::before {
        background: var(--satisfied);
      }
      & > .subtree-node {
        &::before {
          border-color: var(--satisfied);
        }
        &::after {
          background: var(--satisfied);
        }
      }
    }
    &.unsatisfied {
      &::before {
        background: var(--unsatisfied);
      }
      & > .subtree-node {
        &::before {
          border-color: var(--unsatisfied);
        }
        &::after {
          background: var(--unsatisfied);
        }
      }
    }
    &.initial {
      &::before {
        background: var(--initial);
      }
      & > .subtree-node {
        &::before {
          border-color: var(--initial);
        }
        &::after {
          background: var(--initial);
        }
      }
    }
  }
  .subtree-title-autocomplete {
    .ant-select-selector {
      height: 26px;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      .ant-select-selection-search {
        &-input {
          padding-left: 37px;
          height: 26px;
        }
      }
    }
  }
  .subtree-title-autocomplete,
  .subtree-title-input {
    width: 249px;
    position: relative;
    height: 26px;
    background: #fcfcfd;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    font-size: 16px;
    line-height: 20px;
    color: #201f1d;
    outline: none;
  }
  .subtree-root-node {
    border-left: 1px solid #929297;
    position: relative;
    left: -1px;
    max-width: 250px;
    height: 65px;
    margin: 50px 0px 10px 0px;
    &::after {
      content: '>';
      position: absolute;
      left: -2px;
      bottom: 0px;
      line-height: 0px;
      transform: rotate(90deg);
    }
    &::before {
      content: '';
      position: absolute;
      left: -12px;
      top: -1px;
      height: 23px;
      width: 23px;
      background-color: #F6F6FA;
      border: 1px solid #929297;
      border-radius: 50%;
      background-image: url(../../assets/images/root-node-icon.svg);
      background-repeat: no-repeat;
      background-position: center center;
      user-select: none;
      z-index: 100;
    }
    .subtree-node {
      top: -2px;
    }
  }
  .subtree-node {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    position: relative;
    width: 250px;
    height: 26px;
    &.cl-node {
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 0px 34px rgba(120, 7, 0, 0.11);
      &.satisfied {
        &::before {
          z-index: 3;
          border-color: var(--satisfied);
        }
        &::after {
          z-index: 3;
          background: var(--satisfied);
        }
        .idea-node-title {
          &-label {
            color: var(--satisfied);
          }
        }
        .filler-line {
          border-color: var(--satisfied);
        }
        .subtree-expand {
          background-color: white;
          border: 1px solid var(--satisfied);
          &-count {
            color: var(--satisfied);
          }
          .anticon {
            color: var(--satisfied);
          }
        }
      }
      &.unsatisfied {
        &::before {
          z-index: 3;
          border-color: var(--unsatisfied);
        }
        &::after {
          z-index: 3;
          background: var(--unsatisfied);
        }
        .idea-node-title {
          &-label {
            color: var(--unsatisfied-text);
          }
        }
        .filler-line {
          border-color: var(--unsatisfied);
        }
        .subtree-expand {
          background-color: white;
          border: 1px solid var(--unsatisfied);
          &-count {
            color: var(--unsatisfied);
          }
          .anticon {
            color: var(--unsatisfied);
          }
        }
      }
      &.initial {
        &::before {
          z-index: 3;
          border-color: var(--initial);
        }
        &::after {
          z-index: 3;
          background: var(--initial);
        }
        .idea-node-title {
          &-label {
            color: var(--initial);
          }
        }
        .filler-line {
          border-color: var(--initial);
        }
        .subtree-expand {
          background-color: white;
          border: 1px solid var(--initial);
          &-count {
            color: var(--initial);
          }
          .anticon {
            color: var(--initial);
          }
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: -6px;
      top: 50%;
      transform: translateY(-50%);
      height: 12px;
      width: 12px;
      background: #f6f6fa;
      border: 1px solid#929297;
      border-radius: 50%;
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      left: -3px;
      top: 10px;
      width: 6px;
      height: 6px;
      background: #929297;
      border-radius: 50%;
      z-index: 2;
    }
    &:hover {
      background-color: #e9e9ed;
    }
    .subtree-title {
      display: inline-flex;
      align-items: center;
      height: 26px;
      vertical-align: middle;
      padding: 1px 0px 1px 20px;
      width: 100%;
      &.selected {
        background-color: #e2e2ff;
        .idea-node-title {
          &-prefix,
          &-label {
            color: #3658e2;
          }
        }
      }
      .filler-line {
        height: 1px;
        width: 100%;
        border-top: 1px dashed #bbb;
      }
      .drag-handle {
        height: 20px;
        width: 20px;
        text-align: center;
        transform: rotate(90deg);
        line-height: 16px;
        border-radius: 2px;
        display: none;
        color: #929297;
      }
      .idea-node-title-prefix {
        border-radius: 3px;
        &:hover {
          svg {
            display: none;
          }
          .drag-handle {
            display: block;
          }
        }
      }
    }
    .subtree-expand {
      display: inline-block;
      cursor: pointer;
      position: relative;
      z-index: 5;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      vertical-align: middle;
      background: #f6f6fa;
      border: 1px solid #929297;
      right: 10px;
      overflow: hidden;
      .anticon,
      .subtree-expand-count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #929297;
        font-size: 12px;
        &:hover {
          background-color: #f3f3f6;
        }
      }
    }
  }
  .subtree-children {
    display: inline-block;
    vertical-align: top;
    .child-container {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 18px;
        left: -1px;
        height: 100%;
        width: 1px;
        background: #929297;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

.subtree-children-drag {
  & > :first-child {
    & > .subtree-node {
      .subtree-title {
        svg {
          display: none;
        }
        .drag-handle {
          display: block;
        }
      }
    }
  }
  .subtree-node {
    background-color: #e9e9ed;
    .subtree-expand {
      display: none;
    }
  }
}
