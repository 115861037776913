.utt-row {
  margin-bottom: 6px;
  position: relative;
  &:last-child {
    margin-bottom: 0px;
  }
  .anticon-close {
    color: #CBCCD0;
    font-size: 11px;
    position: absolute;
    right: -18px;
    top: 18px;
    cursor: pointer;
  }
}

.utt-unsatisfied {
  .ant-collapse-header {
    border-left:  2px solid #FF6056;
  }
  .utterance-panel-node {
    &[data-last="true"] {
      .utterance-panel-node-prefix {
        border-color: #FF6056;
        &::after {
          background-color: #FF6056;
        }
      }
      .idea-node-title-suffix,
      .idea-node-title-label {
        color: #EA554B;
      }
    }
  }
}

.utt-satisfied {
  .ant-collapse-header {
    border-left:  2px solid #71E1AC;
  }
  .utterance-panel-node {
    &[data-last="true"] {
      .utterance-panel-node-prefix {
        border-color: #53c47e;
        &::after {
          background-color: #53c47e;
        }
      }
      .idea-node-title-suffix, 
      .idea-node-title-label {
        color: #53c47e;
      }
    }
  }
}

.utt-initial {
  .utterance-panel-node {
    &[data-last="true"] {
      .utterance-panel-node-prefix {
        border-color: #3658E2;
        &::after {
          background-color: #3658E2;
        }
      }
      .idea-node-title-suffix, 
      .idea-node-title-label {
        color: #3658E2;
      }
    }
  }
}

.utterance-collapse {
  border: none;
}

.utterance-panel {
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 3px;
  overflow: hidden;
  &.panel-phrase {
    .ant-collapse-header {
      padding: 12px 35px 12px 16px !important;
    }
  }
  .ant-collapse-header {
    font-size: 16px;
    line-height: 20px;
    color: #201f1d;
    padding: 8.5px 35px 8.5px 16px !important;
    background: #ffffff;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 13px;
      line-height: 20px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #94949B;
    }
  }
  &-nodes {
    display: flex;
    flex-direction: column;
    padding: 12px 0px;
    position: relative;
    border-bottom: 1px dashed #DEDEDE;
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }
    &:hover {
      .ant-btn {
        opacity: 1;
        pointer-events: all;
      }
    }
    .utterance-panel-node {
      &[data-last="true"] {
        .idea-node-title-label {
          max-width: 150px;
        }
        .utterance-panel-node-prefix {
          .node-prefix-arrow {
            display: none;
          }
        }
        .idea-node-title-suffix {
          display: initial;
        }
      }
      &-prefix {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        background: #FFFFFF;
        border: 1px solid #929297;
        border-radius: 50%;
        position: relative;
        margin-right: 4px;
        &::after {
          content: '';
          position: absolute;
          left: 2px;
          top: 2px;
          width: 6px;
          height: 6px;
          background: #929297;
          border-radius: 50%;
        }
        .node-prefix-arrow {
          position: absolute;
          left: 3.5px;
          top: 10px;
        }
      }
      &-title {
        display: flex;
        align-items: center;
      }
      &-children {
        padding-left: 12px;
        margin-left: 12px;
      }
      .idea-node-title-suffix {
        display: none;
      }
    }
   .ant-btn {
      opacity: 0;
      position: absolute;
      top: 14px;
      right: 0;
      pointer-events: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      background: #EDEDF3;
      border-radius: 3px;
      border: none;
      padding: 2px 8px;
      box-shadow: none;
      transition-duration: 100ms;
    }
  }
}