.app-status {
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  list-style: none;
  &-item {
    font-family: "Fira Code";
    line-height: 1.8em;
    &-label {
      font-weight: bold;
      font-family: "Fira Code";
    }
  }
}
