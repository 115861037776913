.weight-dials {
  .weight-dial {
    padding: 4px;
    .ant-input-number {
      background: none;
      border: none;
      box-sizing: border-box;
      margin-left: 5px;
      width: 70px;
      box-shadow: none;
      outline: none;
      background-color: rgba(128, 128, 128, 0.5);
      .ant-input-number-input {
        color: white;
        background-color: inherit;
        height: 25px;
        padding: 5px;
      }
    }
  }
}

.idea-graph-node-editor {
  --graph-header: 85px;
  --panel-header-height: 100px;
  padding: 0;
  z-index: 30;
  position: fixed;
  right: 10px;
  top: 75px;
  height: calc(100% - var(--graph-header));
  background: #ffffff;
  box-shadow: -6px 5px 10px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  width: 400px;

  .graph-node-editor-select {
    margin-bottom: 10px;
    width: 100%;
    .ant-select-selector {
      background: #f5f5f7;
      border-radius: 2px;
      border: none;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .graph-node-editor-input,
  .graph-node-editor-input .ant-input {
    background: #f5f5f7;
    border-radius: 2px;
    height: 30px;
    border: none;
    outline: none;
    box-shadow: none;
    .ant-input-group-addon {
      background: inherit;
      border: none;
      user-select: none;
      font-size: 11px;
      line-height: 26px;
      color: #3658E2;
      span {
        font-weight: 600;
      }
    }
  }
  .graph-node-editor-impact {
    font-weight: 300;
    font-size: 13px;
    line-height: 17px;
    color: #3658e2;
    margin-right: 20px;
    & > span {
      color: #ceced7;
    }
  }
  .graph-node-editor-group {
    &.__flex {
      display: flex;
      align-items: center;
    }
  }
  .graph-node-editor-header {
    height: var(--panel-header-height);
    &-controls {
      height: 55px;
      background: #ffffff;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    }
    &-label {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }
    &-path {
      height: 45px;
      display: flex;
      align-items: center;
      background: #F5F5F7;
      border-top: 1px solid #E4E4EA;
      border-bottom: 1px solid #E4E4EA;
    }
  }
  .graph-node-editor-body {
    overflow-y: auto;
    height: calc(100% - var(--graph-header) - 15px);
    .ant-tabs {
      &-nav {
        &-wrap {
          background: #f5f5f7;
        }
        .ant-tabs-tab {
          border: none !important;
          margin: 0 !important;
          background: #f5f5f7;
          .ant-tabs-tab-btn {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }
          &-disabled {
            .ant-tabs-tab-btn {
              color: #a0a1b3;
            }
          }
          &-active {
            background: #ffffff;
            .ant-tabs-tab-btn {
              color: #000000;
            }
          }
        }
      }
      &-content-holder {
        padding: 0px 30px;
      }
    }
  }
  .graph-node-editor-section {
    padding-bottom: 35px;
    &:first-child {
      padding-top: 20px;
    }
    &-title {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: #c4c5cf;
      margin-bottom: 20px;
    }
    &-products {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }
}

.graph-phrases-editor {
  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  &-list {
    padding-top: 10px;
  }
  .node-editor-phrases-order {
    cursor: pointer;
    & > .anticon,
    span {
      color: #3658e2;
    }
  }
}

.idea-node-setting-group {
  &.__flex {
    display: flex;
    .idea-node-setting-label {
      font-size: 15px;
      line-height: 26px;
      color: #9798a9;
    }
  }
}

.idea-node-setting {
  padding-top: 12px;
  &.__flex {
    display: flex;
    margin-right: 10px;
    align-items: baseline;
    &:last-child {
      margin-right: 0px;
    }
    .idea-node-setting-label {
      padding-right: 10px;
    }
  }
  &-label {
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 6px;
  }
  .ant-input {
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 3px;
  }
}

.node-child-row {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 10px rgba(1, 4, 77, 0.04);
  border-radius: 3px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  position: relative;
  font-size: 16px;
  line-height: 21px;
  padding-left: 8px;
  &.__padding {
    height: auto;
    padding: 10px 8px;
    .idea-node-title-label {
      max-width: 250px;
    }
  }
  &.__flex {
    display: flex;
    justify-content: space-between;
  }
  &.__drag {
    z-index: 100;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  &-weight {
    background: #f5f5f7;
    border-radius: 2px;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #3658e2;
    min-width: 20px;
    display: inline-block;
    flex-shrink: 0;
    margin: 0px 10px;
    flex-shrink: 0;
    padding: 0 5px;
  }
  &-menu {
    color: #c4c4c4;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    user-select: none;
  }
  .ant-input {
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    height: 35px !important;
    border: none;
    outline: none;
    box-shadow: none;
  }
  .drag-handle {
    position: absolute;
    left: -15px;
    cursor: pointer;
    transform: rotate(90deg);
    color: #D3D3DF;
  }
}

.node-child-product {
  background: #ffffff;
  border: 1px solid #3658e2;
  box-shadow: 0px 0px 10px rgba(1, 4, 77, 0.04);
  border-radius: 4px;
  height: 200px;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  .product-img {
    height: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    overflow: hidden;
    img {
      position: relative;
      top: -20%;
    }
  }
  .product-desc {
    height: 50%;
    background: #f1f1f1;
    padding: 6px 10px 0px 10px;
    border-top: 1px solid #3658e2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 35px;
      flex-shrink: 0;
    }
    &-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      align-items: center;
      padding-bottom: 4px;
    }
  }
}