/* overall styles */
* {
  margin: 0;
  padding: 0;
  font-family: National;
}

.plain-text {
  color: rgba(43, 45, 66, 0.65);
  cursor: pointer;

  &:hover {
    color: rgba(43, 45, 66, 1);
  }
}

/* buttons */
button {
  font-size: 17px;
  font-weight: bold;
  outline: none;
  cursor: pointer;

  &.default {
    background-color: white;
    border: 2px solid #DB295E;
    border-radius: 60px;
    color: #DB295E;
    font-size: 17px;
    line-height: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.83px;
    display: inline-block;
    padding: 5px 18px 6px 18px;
    margin: 4px 2px;
    box-shadow: 0px 3px rgba(43,45,66,0.15);
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    cursor: pointer;

    &:hover {
      background: #db295e;
      color: #fff;
      transform: translateY(-1px);
      box-shadow: 0 4px rgba(43, 45, 66, 0.3);
    }
  }
  &.default-wide{
    @extend .default;
    padding: 5px 36px 6px 36px;
  }

  &.plain {
    font-size: 17px;
    line-height: 18px;
    color: rgba(43, 45, 66, 0.65);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.83px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: rgba(43, 45, 66, 1);
    }
  }

  &.plain-back{
    @extend .plain;
    position: relative;
    margin-left: 12px;
    &:hover:before{
      opacity: 1;
    }
    &:before{
      content: '';
      background: url(./icons/left-arrow.svg) no-repeat;
      background-size: 11px 11px;
      display: block;
      height: 11px;
      width: 11px;
      position: absolute;
      left: -14px;
      top: 5px;
      opacity: 0.65;
    }
  }

  &.plain-pink {
    font-size: 17px;
    line-height: 18px;
    color: #DB295E;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.83px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: #A30035;
    }
  }

  &.plain-underline {
    font-size: 14px;
    line-height: 15px;
    color: rgba(43,45,66,0.65);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.83px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: rgba(43, 45, 66, 1);
      text-decoration: underline;
    }
  }
}

.back {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.29px;
  color: rgba(43, 45, 66, 0.6);
  cursor: pointer;

  &:hover {
    img {
      opacity: 1;
    }
  }

  button {
    font-size: 14px;
    height: 20px;
  }
  img {
    margin-top: 1px;
    height: 12px;
    margin-right: 5px;
    opacity: 0.8;
  }

}

/* modal related elements */
div.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow-y: auto;
  z-index: 1000;
  background-color: white;
}

.body-modal-open{
  overflow: hidden;
}

.cross {
  position: absolute;
  top: 0;
  right: 0;
  margin: 28px 28px 0 0;
  opacity: 0.6;
  img { height: 50px; width: 50px; }
  &:hover { cursor: pointer; opacity: 1; }
}

/* icons */
.caret {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 12px;
  cursor: pointer;

  img {
    opacity: 0.65;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}
