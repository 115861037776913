.loading{
  .g-quote-loader-wrap {
    position: absolute;
    width: 75px;
    height: 75px;
    left: 50%;
    margin-left: -38px;
    top: 50%;
    margin-top: -38px;
  }
  .g-quote-loader-wrap__quote {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.2%, -48%) scale(0.3);
    z-index: 5;
  }

  .g-quote-loader-wrap__pink-dot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .g-quote-loader-wrap svg * {
    transform-origin: center;
    /* or transform-origin: 50% */
    transform-box: fill-box;
  }
  .g-quote-loader-wrap svg circle#outerCoreLoader {
    opacity: 0;
    transition: all 0.18s ease-in-out;
  }
  .g-quote-loader-wrap svg circle#outerCoreLoader.loading {
    animation: pulsate 1.5s infinite;
    transition: all 0.18s ease-in-out;
  }
  .g-quote-loader-wrap svg circle#outerCore {
    transform: scale(1);
    opacity: 1;
    transition: all 0.18s ease-in-out;
  }
  .g-quote-loader-wrap svg circle#outerCore.loading {
    opacity: 0;
    transform: scale(0.4);
  }
  @keyframes pulsate {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
