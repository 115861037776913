@keyframes mountActive {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.active-users {
  position: relative;
  left: 5px;
  white-space: nowrap;

  .ant-avatar {
    box-sizing: content-box;
    margin: 0;
    position: relative;
    opacity: 0;
    transform: translate3d(3px, 0, 0);
    animation: mountActive 200ms ease-out forwards;

    &.users-hidden {
      background: #e8e8f2;
      cursor: default;
      .ant-avatar-string {
        font-size: 10px;
        line-height: 18px;
        color: #8c8c8c;
      }
    }

    &:only-child {
      right: 5px !important;
    }
  }
}
