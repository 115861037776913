@import '../../css/colors';
@import '../../css/variables';


.mobile-signup{
  display: flex;
  align-items: center;
  flex-direction: column;
  height:100%;
  padding-top:80px;
  padding-left: 7%;
  padding-right: 7%;
  background-color: white;
  .signup-page__form__body{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    &__title{
      text-align: center;
    }
    .signup-page__form__body__description{
      text-align: center;
    }
  }
}

.signup-page{
	background-color: $dark-blue;
  position: relative;
  height:100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color:$dark-blue;
  span, div, label, a{
    font: $fontBody1;
  }

  .sign-in-link{
    margin-top: 37px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    a{
      text-decoration: underline;
    }
  }

  h1{
    font: $fontHeader1;
  }
  &__brand{
      position: absolute;
      top:0;
      left:0;
      width:50%;
      height:100%;
      padding:70px;
      box-shadow: 6px 0px 17px 0px rgba(12, 5, 58, 0.2);
      z-index: 2;
    &__message{
      position: relative;
      top:50%;
      transform:translateY(-50%);
      p{
        font-size:1.17em;
        line-height: 1.4em;
      }
      h1, p{
        color:white;
      }
    }
    &__bg{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.15;
    }
  }
  &__form{
    position: absolute;
    width: 50%;
    height:100%;
    padding:80px;
    background-color: white;
    margin:0 auto;
    right:0;
    top:0;
    &__head{
      &__wordmark{
        position: relative;
        width:110px;
        height:auto;
      }
    }
    &__body{
      position: relative;
      top:50%;
      transform:translateY(-50%);
      &__description{
        margin-bottom: 4em;
        color: rgba(0,0,0,0.5);
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
      }
      &__title{
        color: #2B2D42;
        font-size: 30px;
        font-weight: 600;
        line-height: 42px;
      }
      &__fields{
        &__item{
          margin-bottom: 15px;
          color:$dark-blue;
          &__forgot-pswd{
            position: absolute;
            right: 1em;
            top: 50%;
            transform: translateY(-50%);
          }
          &__remember{
            color:$dark-blue;
          }
        }
        &__step{
          margin-bottom: 4em;
          &__item{
            margin-bottom: 15px;
            color:$dark-blue;
            &__input{
              &--madlib{
                display: inline-block;
                width: 170px;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #dddee2;
                color: $gamalon-pink;
                &:focus{
                  box-shadow: none;
                }
              }
            }
            &__forgot-pswd{
              position: absolute;
              right: 1em;
              top: 50%;
              transform: translateY(-50%);
            }
            &__remember{
              color:$dark-blue;
            }
          }
        }
        &__control{
          height: 2.9em;
          position: relative;
          &__item{
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
            color:$dark-blue;
            &--btn{
              width: 9em;
              position: absolute;
              right: 0;
              &__button{
                width: 100%;
                &__text{
                  font-weight: 500;
                  margin-right:1.5rem;
                  margin-left: 0 !important;
                  position: absolute !important;
                  left: 1em;
                  top: 50%;
                  transform: translateY(-50%);
                }
                .anticon-loading{
                  position: absolute;
                  right: 8px;
                  top: 50%;
                  transform: translateY(-50%);
                  width:25px;
                  height:25px;
                  svg{
                    position: relative;
                    display: block;
                    top: 5px;
                    margin: 0 auto;
                  }
                }
                &__icon{
                  font-size: 0.85em;
                  width: 2em;
                  height: 2em;
                  background-color: rgba(255, 255, 255, 0.16);
                  border-radius: 0.2em;
                  position: absolute;
                  right: 0.5em;
                  top: 50%;
                  transform: translateY(-50%);
                  svg{
                    position: relative;
                    display: block;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: 0 auto;
                  }
                }
                &.ant-btn-loading{
                  padding-left:0 !important;
                  .signup-page__form__body__fields__control__item--btn__button__icon{
                    display: none;
                  }
                }
              }
            }
            &__signin{
              margin-left:0.5em;
            }
          }
        }
      }
    }
  }

	.firebaseui-card-footer {
		font-size: 10px;
	}
}
