.check-mobile{
  background: #FCF7F0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  .gamalon-logo{
    margin-top: 36px;
  }
  &-image{
    margin-top: 104px;
  }
  .title {
    padding: 0px 20px;
  }
  .title, .title a {
    margin-top: 40px;
    font-family: Tiempos Headline;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 123.8%;
    text-align: center;
    color: #201F1D;
    a {
      text-decoration: underline;
      color: #1d39c4;
    }
  }
  .suggestion{
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 135.2%;
    text-align: center;
    color: #201F1D;
  }
}
