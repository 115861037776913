.add-teammate-modal{
  .ant-modal-header{
    .ant-modal-title{
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #9A9DB0;
    }
  }
  .ant-modal-body{
    .input-field{
      margin-bottom: 16px;
    }
  }
  input{
    background: #F1F1F5;
    height: 33px;
    border-radius: 4px;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125.56%;
    display: flex;
    align-items: center;
    color: #201F1D;
  }
  .ant-select{
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
    width: 120px;
    .ant-select-selector{
      background: #F1F1F5;
      border-radius: 3px;
      border: none;
    }
  }
}


.chat-settings-team{
  .ant-table{
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    box-sizing: border-box;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    margin-top: 23px;
    .ant-table-thead{
      .ant-table-cell{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
        color: #A5A5BB;
        background: #FFFFFF;
      }
    }
    .ant-table-footer{
      padding: 8px 8px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      .add-teammate-footer{
        .ant-btn-text{
          color: #00000f;
          span{
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 133.8%;
            text-align: center;
            text-transform: uppercase;
            color: #9296AC;
          }
        }
      }
    }
    .ant-table-tbody{
      font-size: 17px;
      line-height: 26px;
      .ant-select{
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #000000;
        width: 120px;
        .ant-select-selector{
          background: #F1F1F5;
          border-radius: 3px;
          border: none;
        }
      }
    }
  }
}
